import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/Chooser.tsx";
import '../styles/index.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Container = makeShortcode("Container");
const Box = makeShortcode("Box");
const Columns = makeShortcode("Columns");
const Column = makeShortcode("Column");
const Heading = makeShortcode("Heading");
const ProductPriceEstimationForm = makeShortcode("ProductPriceEstimationForm");
const StaticMapBuyers = makeShortcode("StaticMapBuyers");
const UsSection = makeShortcode("UsSection");
const RevealFade = makeShortcode("RevealFade");
const Content = makeShortcode("Content");
const AppSection = makeShortcode("AppSection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section id='/' size='medium' style={{
      backgroundColor: '#f8fff8',
      backgroundImage: 'url(https://images.unsplash.com/photo-1558871585-4c3574a1b7cd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2251&q=80)',
      backgroundSize: 'cover',
      backgroundPosition: '0 20%'
    }} mdxType="Section">
  <Container className='presentation' mdxType="Container">
    <Box mdxType="Box">
      <Columns mdxType="Columns">
        <Column size={6} mdxType="Column">
          <br />
          <Heading renderAs='h1' size={2} className='handwriting' mdxType="Heading">
            Mettez votre matière <strong className='highlighted'>en vente</strong>
          </Heading>
          <ProductPriceEstimationForm mdxType="ProductPriceEstimationForm" />
          <br />
        </Column>
        <Column size={6} display='relative' style={{
              position: 'relative !important'
            }} mobile={{
              display: 'hidden'
            }} mdxType="Column">
          <StaticMapBuyers geolocation mdxType="StaticMapBuyers" />
        </Column>
      </Columns>
    </Box>
  </Container>
    </Section>
    <Section id='us' size='medium' mdxType="Section">
  <UsSection mdxType="UsSection" />
    </Section>
    <Section id='how' size='medium' backgroundColor='light' mdxType="Section">
  <Container mdxType="Container">
    <Heading renderAs='h2' size={2} textAlign='center' className='handwriting' mdxType="Heading">
      <strong className='highlighted'>Vendez vos matières</strong> en 3 étapes
    </Heading>
    <hr />
    <RevealFade cascade mdxType="RevealFade">
      <Columns mt={6} mdxType="Columns">
        <Column mdxType="Column">
          <Heading renderAs='h4' size={4} mdxType="Heading">
            1. La livraison test
          </Heading>
          <Content mdxType="Content">
            <ul>
              <li>Vous proposez 1 à 3 camions de matière à plusieurs acheteurs de votre région</li>
              <li>Nous viendrons charger la matière sur une journée test et la livrerons pour vous</li>
              <li>Nous organiserons le paiement sur votre compte bancaire immédiatement après les livraisons</li>
              <li>Les acheteurs testent votre matière et vous proposent des tarifs pour des contrats multi-annuels</li>
            </ul>
          </Content>
        </Column>
        <Column mdxType="Column">
          <Heading renderAs='h4' size={4} mdxType="Heading">
            2. La contractualisation
          </Heading>
          <Content mdxType="Content">
            <ul>
              <li>Vous sélectionnez l’acheteur qui vous propose le meilleur tarif</li>
              <li>
                Nous préparons le contrat selon vos capacités de fournir la matière (volume, saisonnalité, tarif,
                matières…)
              </li>
              <li>Vous signez un contrat avec les fournisseurs via la plateforme ENIA</li>
            </ul>
          </Content>
        </Column>
        <Column mdxType="Column">
          <Heading renderAs='h4' size={4} mdxType="Heading">
            3. Les livraisons et les paiements
          </Heading>
          <Content mdxType="Content">
            <ul>
              <li>Après signature du contrat nous programmons les tournées de transport régulières</li>
              <li>Vous assurez le chargement des matières lors des rdv</li>
              <li>Nous sécurisons le paiement des livraisons</li>
            </ul>
          </Content>
        </Column>
      </Columns>
    </RevealFade>
  </Container>
    </Section>
    <AppSection mdxType="AppSection" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      